import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"
import CaseStudyMichaelBreusTemplate from "../modules/caseStudy/CaseStudyMichaelBreus.template"

const CaseStudyMichaelBreus = ({ data }) => {
  return (
    <Layout>
      <SEO title="Case Study Michael Breus" />
      <CaseStudyMichaelBreusTemplate image={data.caseStudyMichaelBreus.edges} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    caseStudyMichaelBreus: allFile(
      filter: { relativeDirectory: { eq: "caseStudyMichaelBreus" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`

export default CaseStudyMichaelBreus
