import React from "react"
import CaseStudyHeaderSection from "./components/CaseStudyHeaderSection"
import CaseStudyDescriptionSection from "./components/CaseStudyDescriptionSection"
import CaseStudyWorkSection from "./components/CaseStudyWorkSection"
import NewsletterSection from "../newsletter/NewsletterSection"

const CaseStudyMichaelBreusTemplate = ({ image }) => {
  return (
    <React.Fragment>
      {" "}
      <CaseStudyHeaderSection
        title="Michael Breus"
        description="The Sleep Doctor. Hook Points help establish an expert/physician and take him to new levels"
        image={image}
      />
      <CaseStudyDescriptionSection
        list={
          <ul>
            <li>
              Helped Dr. Michael Brues{" "}
              <span>establish and expand a personal brand</span> to bring his
              unique message and expertise to people around the world
            </li>
            <li>
              We developed Hook Points to{" "}
              <span>
                capture and captivate audiences, get speaking events and media,
              </span>{" "}
              while creating a strategy to grow his Instagram page
            </li>
            <li>
              Michael’s Instagram grew from 6,000 to{" "}
              <span>100,000 targeted, relevant followers</span> in a matter of
              weeks, resulting in a $20,000 speaking gig with no previous
              speaking experience, and dozens of{" "}
              <span>high-level press opportunities</span> including the{" "}
              <span>Dr. Oz show</span>
            </li>
          </ul>
        }
      />
      <CaseStudyWorkSection
        next={"/case-study-one-million-followers"}
        text="There’s more to learn about how Hook Points drove opportunity for The Sleep Doctor. "
        subText="Let us tell you more"
      />
      <NewsletterSection />
    </React.Fragment>
  )
}

export default CaseStudyMichaelBreusTemplate
